import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router';

import BasicSkynetFileProcessingDemo from './pages/BasicSkynetFileProcessingDemo.vue';
import SampleFiles from './pages/SampleFiles.vue';
import SwaggerUI from './pages/SwaggerUI.vue';
import ZUGFeRDValidierung from './pages/ZUGFeRDValidierung.vue';
import { buildApiURL } from './shared';

const createHistory = process.env.SERVER
	? createMemoryHistory
	: process.env.VUE_ROUTER_MODE === 'history'
	? createWebHistory
	: createWebHashHistory;

const routes = [
	{
		path: '/',
		redirect: '/xrechnung-visualisierung',
	},
	{
		path: '/xrechnung-visualisierung',
		component: BasicSkynetFileProcessingDemo,
		props: {
			name: 'XRechnung Visualisierung',
			pageID: 'xrechnung-visualisierung',
			fileInputLabel: 'Wählen Sie hier eine XRechnung-XML-Datei aus oder ziehen Sie es per Drag & Drop',
			url: buildApiURL('/e-invoice/visualize/with-validation?invoker.response=single-file'),
			resultingContentType: 'application/pdf',
		},
	},
	{
		path: '/xrechnung-validierung',
		component: BasicSkynetFileProcessingDemo,
		props: {
			name: 'XRechnung Validierung',
			pageID: 'xrechnung-validierung',
			fileInputLabel: 'Wählen Sie hier eine XRechnung-XML-Datei aus oder ziehen Sie es per Drag & Drop',
			url: buildApiURL('/e-invoice/validate?invoker.response=single-file'),
			resultingContentType: 'application/json',
		},
	},
	{
		path: '/zugferd-validation',
		component: ZUGFeRDValidierung,
		props: {
			name: 'ZUGFeRD Validierung',
			pageID: 'zugferd-validation',
			fileInputLabel: 'Wählen Sie hier eine ZUGFeRD-PDF-Datei aus oder ziehen Sie es per Drag & Drop',
			url: buildApiURL('/experimental/pdf/validate/zugferd?invoker.response=single-file'),
			resultingContentType: 'application/json',
		},
	},
	{
		path: '/ocr',
		component: BasicSkynetFileProcessingDemo,
		props: {
			name: 'PDF OCR',
			pageID: 'ocr',
			fileInputLabel: 'Wählen Sie hier eine PDF-Datei aus oder ziehen Sie sie per Drag & Drop',
			url: buildApiURL('/experimental/pdf/convert/recognise_text?invoker.response=single-file'),
			resultingContentType: 'application/pdf',
		},
	},
	{
		path: '/pdfxua',
		component: BasicSkynetFileProcessingDemo,
		props: {
			name: 'PDF zu PDF/UA',
			pageHeaderID: 'pdfxua-page-header',
			pageID: 'pdfxua',
			fileInputLabel: 'Wählen Sie hier eine PDF-Datei aus oder ziehen Sie sie per Drag & Drop',
			url: buildApiURL('/experimental/pdf/convert/pdfxua?invoker.response=single-file'),
			resultingContentType: 'application/pdf',
		},
	},
	{
		path: '/sample-files',
		component: SampleFiles,
	},
	{
		path: '/swagger',
		component: SwaggerUI,
	},
];

const router = createRouter({
	scrollBehavior: () => ({ left: 0, top: 0 }),
	routes,
	history: createHistory(process.env.VUE_ROUTER_BASE),
});

export default router;
